<template >
  <div id="visual">
    <!-- <iframe frameborder="no" border="0" src="https://lbs.baidu.com/jsdemo/demo/webgl6_1.htm"
			class="iframe"></iframe> -->
    <!-- 顶部标题 -->
    <div class="v-top">
      <div class="title-bottom"></div>
      <div class="back_btn">
        <el-button type="primary" @click="goBack" size="mini">返回</el-button>
        <el-button type="primary" @click="screen" size="mini">全屏</el-button>
      </div>
      <div class="title-center"></div>
      <div class="title-top">
        <span>
          {{wallTitle ? wallTitle:'智慧在线监测监管平台' }}
        </span>
      </div>
      <!--预警-->
      <div class="title-alarm">
        <el-popover placement="bottom" width="250" trigger="click" style="max-height: 500px;overflow-x: hidden;">
          <template v-if="alarmData.length>0">
            <el-timeline>
              <el-timeline-item :timestamp="item.time" placement="top" type="danger" v-for="(item,key) in alarmData" :key="key">
                <div style="font-weight: bold;">{{item.title || item.uid}}</div>
                <div v-for="(e,key) in item.items" :key="key">
                  {{e.title}}超过阀值 {{e.cfgValue}}
                </div>
              </el-timeline-item>
            </el-timeline>
          </template>
          <div v-else style="text-align:center;">无报警设备</div>
          <el-badge slot="reference" :value="alarmData.length > 0 ? alarmData.length : ''" class="item">
            <i style="font-size:20px" class="notify el-icon-bell"></i>
          </el-badge>
        </el-popover>
      </div>
      <!--时间-->
      <div class="title-time">
        {{curTime}}
      </div>
    </div>
    <!-- 布局 -->
    <component v-if="container" :is="container" :mapdata="mapdata"></component>
    <VisualDialog v-else :dialogVisible="visualConfigShow" @sure="visualReady"></VisualDialog>
  </div>
</template>

<script>
//要使用懒加载 不然样式冲突

//道路积水救援系统
const RPWS = () => import('../components/Visual/visual.rpws.vue');

//智慧河道水雨情在线监测管理平台 大亚湾
const Default = () => import('../components/Visual/visual.dayawan.vue');

//大屏配置弹窗
const VisualDialog = () => import('../components/Visual/visual.config.vue');

import VisualConfig from "../common/visualConfig.js"

export default {
  data() {
    return {
      timer: null,
      curTime: '',
      customTitle: '',
      hourCache: -1,
      logo: {},
      alarmData: [],
      container: "", // 内部大屏标识
      wallTitle: "",  //顶部文字
      mapdata: {},
      visualConfigShow: true,
    }
  },
  components: {
    RPWS,
    Default,
    VisualDialog,
  },
  watch: {
    curTime: {
      handler() {
        return new Date();
      }
    },
    "$store.state.alarmData": {  //监听报警变化
      handler: function (newVal, oldVal) {
        this.alarmData = this.$store.state.alarmData //获取全局存储
      },
    },
    "$store.state.settingStore.dbName": { //大屏配置变化
      handler: function (n, o) {
        this.getVisualData()
      },
    }
  },
  methods: {
    //返回
    goBack() {
      this.$router.push({ path: '/public/live_data' })
    },
    //全屏
    screen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },

    getVisualData() {
      //获取大屏标识
      this.container = this.$store.state.settingStore.dbName;
      if (this.container != "") {
        let { title, mapdata } = VisualConfig.getVisualConfig(this.container)
        this.wallTitle = title  //标题
        this.mapdata = mapdata  //地图数据
      }
    },
    //隐藏组件
    visualReady() {
      this.dialogVisible = false
    },

  },
  created() {
    this.getVisualData()

  },
  mounted() {
    this.timer = setInterval(() => {
      let date = this.common.cGetDate('-');
      let time = this.common.cGetTime(':');
      this.curTime = date + " " + time;
    }, 1000)
  },
  beforeCreate() {
    document.getElementsByTagName("body")[0].className = "body-bg"
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    document.body.removeAttribute("class", "body-bg")
  },

}
</script>

<style lang="scss">
.body-bg {
  background-color: #010101;
  font-size: 12px;
  color: #dddddd;
}

#visual {
  $top-h: 65px;
  $border_color: #077ccb;
  $title_color: #0c72f8;
  $boxborder_color: #02a6b5; //折角颜色
  $boxborder_wh: 0.3rem; //边框折角长度
  $boxback_color: #0c216b; //box框背景色

  .iframe {
    width: 100%;
    height: calc(100vh - 3px);
  }

  // 顶部标题
  .v-top {
    height: $top-h;
    box-sizing: border-box;
    text-align: center;
    font-size: 20px;
    // border-bottom: 1px solid #28567A;
    // box-shadow: 0 1px 8px #28567A;
    // text-shadow: 1px 1px 0 $border_color;

    .title-bottom {
      height: 45px;
      background: #091838;
    }

    .title-center {
      // width: 600px;
      width: 50%;
      height: 0;
      border-top: 65px solid #0a1b3e;
      border-left: 80px solid transparent;
      border-right: 80px solid transparent;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      filter: drop-shadow(0 1px 4px #2490eb);
    }

    .title-top {
      height: 65px;
      font-size: 40px;
      letter-spacing: 5px;
      font-weight: bold;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      filter: drop-shadow(2px 2px 2px #000000);
      display: -webkit-box;
      -webkit-box-pack: center;
      -webkit-box-align: center;
      background-image: -webkit-gradient(
        linear,
        0 0,
        0 bottom,
        from(#2490eb),
        to(#48cbff)
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .title-alarm {
      color: #ffffff;
      position: absolute;
      font-size: 12px;
      top: 12px;
      right: calc(100vw / 3 - 370px);
    }
    .title-time {
      color: #ffffff;
      position: absolute;
      top: 12px;
      right: 12px;
    }
    .back_btn {
      position: absolute;
      top: 12px;
      left: 12px;
      z-index: 999;
    }
  }

  .v-title {
    position: absolute;
    width: 450px;
    top: 20px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 5px;
    border-radius: 50%;
    // background-image: -webkit-gradient(linear, 0 0, 0 bottom, from(#2490EB), to(#42B8EA));
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    background-color: #010101;
    z-index: 1;
  }
}
</style>